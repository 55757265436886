import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import Swiper from "react-id-swiper"
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import "swiper/css/swiper.css"
import { Navigation, Pagination } from "swiper/js/swiper.esm"
import { testimonialCareer } from "../components/testimonialsCareerData"

import tesImg from "../images/new-images/Image6-min.jpg"

const ContactModal = props => {
  const { buttonLabel, item } = props
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  // console.log(item)

  return (
    <>
      <Button
        aria-label="btn"
        style={{
          background: "none",
          border: "none",
          padding: 0,
          textTransform: "capitalize",
          color: "#00B6B0",
        }}
        onClick={toggle}
      >
        {buttonLabel}
      </Button>

      <Modal
        centered={true}
        isOpen={modal}
        toggle={toggle}
        className="contactNewTesti"
      >
        <ModalHeader toggle={toggle}>
          {/* <h4 className="mb-0">{item.title}</h4> */}
        </ModalHeader>
        <ModalBody>
          {item.review}
          <br />
          <br />
          <div className="testimonial-holder">
            <div className="testimonial-meta" style={{ marginTop: "2rem" }}>
              {item?.image && (
                <img
                  src={item.image}
                  alt={item.reviewer}
                  style={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    border: "1px solid #0005",
                  }}
                />
              )}
              <p className="h5 title mb-0" style={{ marginTop: "0.7rem" }}>
                {item.reviewer}
              </p>
              <p
                className="h6 title mb-0"
                style={{
                  marginTop: "0.5rem",
                  fontStyle: "italic",
                }}
              >
                {item.position}
              </p>
              {item?.company && <p className="company">{item.company}</p>}
              {item?.linkedIn && (
                <div
                  style={{
                    marginTop: "0.5rem",
                  }}
                >
                  <a
                    href={item.linkedIn}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://cdn.sanity.io/images/55lcecww/production/487a73f5f43fc5e3f45893a43d8e851f65c4f932-512x512.webp"
                      alt="LinkedIn"
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "5px",
                      }}
                    />
                  </a>
                </div>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

function Testimonial() {
  // console.log(contactTestimonial)
  const params = {
    modules: [Pagination, Navigation],
    slidesPerView: 1,
    spaceBetween: 30,
    containerClass: "swiper-container pb-5",
    nested: true,
    autoplay: {
      delay: 9000,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  }

  return (
    <section id="team-test" style={{ padding: "1rem 0 3rem" }}>
      <ScrollAnimation
        animateIn="fadeIn"
        initiallyVisible={false}
        animateOnce={true}
      >
        <div id="team-testimonial-container">
          <div className="container">
            <div className="row">
              <div className="col-md-6 p-0">
                <div className="testimonial-main-image">
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid rounded"
                    src="https://cdn.sanity.io/images/55lcecww/production/1948a3448a032e17f23c68921fc4e007538aef90-3144x1776.webp"
                    alt="testimonial"
                    layout="constrained"
                    loading="lazy"
                  />
                </div>
                <div className="mobile-testimonial-img">
                  <img
                    placeholder="blurred"
                    className="img-fluid"
                    src={tesImg}
                    alt="Testimonial Image"
                    layout="constrained"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="testimonial-swiper">
                  <div className="container">
                    <Swiper {...params}>
                      {testimonialCareer.map((item, idx) => {
                        const limitReview = item.review.substring(0, 220)

                        return (
                          <div key={idx} className="swiper-slide">
                            <div className="testimonial-item">
                              <div className="testimonial-item-inner border-shadow">
                                <div className="testimonial-header">
                                  {item.title && (
                                    <p className="h4">{item.title}</p>
                                  )}
                                </div>
                                <div
                                  className="testimonial-content newContent"
                                  style={{ marginTop: "2rem" }}
                                >
                                  <p style={{ marginBottom: "0px" }}>
                                    {limitReview}...
                                  </p>
                                  {limitReview.length >= 220 ? (
                                    <span>
                                      <ContactModal
                                        item={item}
                                        buttonLabel="Read More"
                                      />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="testimonial-holder">
                                  <div
                                    className="testimonial-meta"
                                    style={{ marginTop: "2rem" }}
                                  >
                                    {item?.image && (
                                      <img
                                        src={item.image}
                                        alt={item.reviewer}
                                        style={{
                                          width: "80px",
                                          height: "80px",
                                          borderRadius: "50%",
                                          objectFit: "cover",
                                          border: "1px solid #0005",
                                        }}
                                      />
                                    )}
                                    <p
                                      className="h5 title mb-0"
                                      style={{ marginTop: "0.7rem" }}
                                    >
                                      {item.reviewer}
                                    </p>
                                    <p
                                      className="h6 title mb-0"
                                      style={{
                                        marginTop: "0.5rem",
                                        fontStyle: "italic",
                                      }}
                                    >
                                      {item.position}
                                    </p>
                                    {item?.company && (
                                      <p className="company">{item.company}</p>
                                    )}
                                    {item?.linkedIn && (
                                      <div
                                        style={{
                                          marginTop: "0.5rem",
                                        }}
                                      >
                                        <a
                                          href={item.linkedIn}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <img
                                            src="https://cdn.sanity.io/images/55lcecww/production/487a73f5f43fc5e3f45893a43d8e851f65c4f932-512x512.webp"
                                            alt="LinkedIn"
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                              borderRadius: "5px",
                                            }}
                                          />
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollAnimation>
    </section>
  )
}

export default Testimonial
